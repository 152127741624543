import React, {useContext} from "react";
import { Outlet, useNavigate } from "react-router-dom";
import AuthedHeader from "./Header";
import { AppContext } from "../../context/AppContext";
import ProfileOffCanvas from "./profile/ProfileOffCanvas";

const AuthedLayout = () => {
  const { appState } = useContext(AppContext);
  const navigate = useNavigate();
  
  if (!appState.user) {
    // If the user is not logged in, redirect to the login page
    navigate("/login");
  }

  return (
    <div className="authed-layout">
      <AuthedHeader />
      <main className="container px-2 px-md-0">
        <Outlet />
        <ProfileOffCanvas />
      </main>
    </div>
  );
};

export default AuthedLayout;
