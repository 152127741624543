import React, { useContext, useEffect, useRef, useState } from "react";
import SailorProfile from "./SailorProfile";
import AddEditSailor from "./AddEditSailor";
import { AppContext } from "../../../context/AppContext";
import { ACTION } from "../../../utils/data";

const ProfileOffCanvas = () => {
  const { appState, setAppState } = useContext(AppContext);
  const offcanvasRef = useRef(null);

  useEffect(() => {
    const offcanvasElement = offcanvasRef.current;

    // Listen for the hide event
    const handleOffcanvasHide = () => {
      setAppState({ ...appState, action: ACTION.VIEW, profile: null });
    };

    // Add the event listener
    offcanvasElement.addEventListener('hide.bs.offcanvas', handleOffcanvasHide);

    // Clean up the event listener on component unmount
    return () => {
      offcanvasElement.removeEventListener('hide.bs.offcanvas', handleOffcanvasHide);
    };
  }, []);

  return (
    <div
      ref={offcanvasRef}
      className="offcanvas offcanvas-end"
      tabIndex="-1"
      id="offcanvasProfile"
      aria-labelledby="offcanvasProfileLabel"
    >
        {appState.profile != null && appState.action === ACTION.VIEW && (
          <SailorProfile profile={appState.profile} />
        )}
        {(appState.action === ACTION.ADD ||
          appState.action === ACTION.EDIT) && (
          <AddEditSailor />
        )}
    </div>

  );
};

export default ProfileOffCanvas;
