import React, { useEffect } from "react";
import { useState, useContext } from "react";
import { ACTION, STATE, STATES, TITLES } from "../../utils/data";
import { getStatusText, setBgByStatus, strToDate } from "../../utils/helper";
import { AppContext } from "../../context/AppContext";
import HeroIcon from "../HeroIcon";
import { searchSailorByCondition } from "../../utils/sb-sailor";
import { formatDate } from "date-fns";

const SailorList = () => {
  const { appState, setAppState } = useContext(AppContext);
  const [searchData, setSearchData] = useState({
    status: "",
    title: "",
    text: "",
  });
  const [data, setData] = useState([]);

  const handleSailorSelect = (item) => {
    setAppState({ 
      ...appState, 
      action: ACTION.VIEW, 
      profile: {
        ...item,
        birthday: strToDate(item.birthday),
        onboard_date: strToDate(item.onboard_date),
        offboard_date: strToDate(item.offboard_date)
      } 
    });
  };

  const onInputChange = (event) => {
    setSearchData({...searchData, [event.target.name]: event.target.value});
  };

  const searchSailor = async () => {
    const response = await searchSailorByCondition(searchData, 1, 100);
    setData(response.items || []);
  }

  useEffect(() => {
    if(appState.search) {
      searchSailor();
      setAppState({ ...appState, search: false });
    }
  }, [appState.search])
  

  return (
    <div className="pt-2 pt-md-3">
      <div className="card">
        <div className="card-header p-2 p-md-3">
          <div className="row g-2 g-md-3">
            <div className="col-6 col-md-3">
              <select
                className="form-select"
                name="title"
                value={searchData.title}
                onChange={onInputChange}
              >
                <option value="">Tất cả chức danh</option>
                {TITLES.map((title, index) => (
                  <option value={title.shortName} key={index}>
                    {title.viName}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-6 col-md-3">
              <select
                className="form-select"
                name="status"
                value={searchData.status}
                onChange={onInputChange}
              >
                <option value="">Tất cả tình trạng</option>
                {STATES.map((state, index) => (
                  <option value={state.id} key={index}>
                    {state.text}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-8 col-md-4">
              <div className="input-group">
                <span className="input-group-text" id="basic-addon1">
                    <HeroIcon name="MagnifyingGlassIcon" width={16} />
                </span>
                <input
                  type="text"
                  name="text"
                  onChange={onInputChange}
                  className="form-control"
                  placeholder="Tìm Kiếm"
                  aria-label="Tìm Kiếm"
                  aria-describedby="basic-addon1"
                />
              </div>
            </div>
            <div className="col-4 col-md-2">
                <button className="btn btn-primary w-100" onClick={searchSailor}>
                <HeroIcon className="me-1" name="MagnifyingGlassIcon" width={16} />
                    {"Tìm Kiếm"}
                </button>
            </div>
          </div>
        </div>
        <div className="card-body overflow-auto  p-0 pt-1">
          <table className="table table-sm table-hover table-striped table-responsive-md">
            <thead>
              <tr>
                <th className="pb-2 sticky-col first-col">#</th>
                <th className="pb-2 text-nowrap sticky-col second-col" scope="col">
                  Họ và Tên
                </th>
                <th scope="col" className="pb-2 text-nowrap">Chức Danh</th>
                <th scope="col" className="pb-2 text-nowrap">Trạng Thái</th>
                <th scope="col" className="pb-2 text-nowrap">Điện Thoại</th>
                <th scope="col" className="pb-2 text-nowrap">Ngày lên tàu</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  <td className="sticky-col first-col">{index + 1}</td>
                  <td className="sticky-col second-col text-nowrap">
                    <a
                      href="#"
                      onClick={() => handleSailorSelect(item)}
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasProfile"
                    >
                      {item.name}
                    </a>
                  </td>
                  <td className="text-nowrap">{item.title}</td>
                  <td className="text-nowrap">
                    <span
                      className={`badge text-dark text-uppercase fw-bold ${setBgByStatus(
                        item.status
                      )}`}
                    >
                      {getStatusText(item.status)}
                    </span>
                  </td>
                  <td className="text-nowrap">{item.phone}</td>
                  <td className="text-nowrap">{formatDate(item.onboard_date, "dd/MM/yyyy")}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SailorList;
