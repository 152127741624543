// src/context/AppContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { supabase } from '../config/supabaseClient';
import { ACTION } from "../utils/data";

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [appState, setAppState] = useState(() => {
    const savedState = localStorage.getItem("AppState");
    
    return savedState
      ? JSON.parse(savedState)
      : { user: null, profile: null, action: ACTION.VIEW, search: true };
  });

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setAppState({...appState, user: session?.user ?? null});
      setLoading(false);
    })

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setAppState({...appState, user: session?.user ?? null});
    });

    // localStorage.setItem("AppState", JSON.stringify(appState));
    return () => subscription.unsubscribe();

  }, []);

  const signIn = async (email, password) => {
    const { data, error } = await supabase.auth.signInWithPassword({ email, password });
    if (error) throw error;
    setAppState({...appState, user: data.user});
    localStorage.setItem("AppState", JSON.stringify({...appState, user: data.user}));
    return data.user;
  };

  const signOut = async () => {
    await supabase.auth.signOut();
    setAppState({...appState, user: null});
    localStorage.setItem("AppState", JSON.stringify({...appState, user: null}));
  };

  return (
    <AppContext.Provider value={{ appState, signIn, signOut, setAppState, setLoading }}>
      {children}
    </AppContext.Provider>
  );
};
