import React, { useContext, useState } from "react";
import { ReactComponent as Logo } from "../logo.svg";
import { AppContext } from "../context/AppContext";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { signIn } = useContext(AppContext);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    const user = await signIn(email, password);
    
    if (user) {
      navigate('/authed');
    }
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-4">
          <div className="card mt-5 pb-3 rounded-4 shadow-lg">
            <div className="card-body p-4">
              <div className="text-center mb-3">
                <a href="/">
                  {" "}
                  <Logo width="90" />{" "}
                </a>
                <h5 className="mb-5">Đăng nhập Quản lý</h5>
              </div>
              {/* Form */}
              <form  onSubmit={handleLogin}>
                <div className="form-group mb-3">
                  <label>Email</label>
                  <input
                    type="email"
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Nhập email"
                  />
                </div>
                <div className="form-group mb-4">
                  <label>Mật khẩu</label>
                  <input
                    type="password"
                    className="form-control"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Nhập mật khẩu"
                  />
                </div>
                <button type="submit" className="btn btn-primary w-100 mt-4">
                  Đăng nhập
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
