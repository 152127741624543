import { supabase } from '../config/supabaseClient';


export const getSailorById = async (id) => {
    const { data, error } = await supabase
      .from('sailors')
      .select('*')
      .eq('id', id)
      .single();
  
    if (error) {
      console.error("Error fetching sailor:", error);
      return null;
    }
    return data;
  };

  export const getAllSailors = async (page, pageSize) => {
    const offset = (page - 1) * pageSize;  // Calculate the offset based on the page number and page size
  
    const { data, error, count } = await supabase
      .from('sailors')
      .select('*', { count: 'exact' })  // Fetch total count for pagination
      .range(offset, offset + pageSize - 1);  // Limit the result based on pagination
  
    if (error) {
      console.error("Error fetching sailors with pagination:", error);
      return { sailors: [], total: 0 };
    }
  
    return {
      items: data,
      total: count,  // Total number of rows in the table
    };
  };

  export const filterSailorByCondition = async (conditions, page, pageSize) => {
    const offset = (page - 1) * pageSize;
    let query = supabase
        .from('sailors')
        .select('*', { count: 'exact' });

    if (conditions.status) {
        query = query.eq('status', conditions.status);
    }
    if (conditions.title) {
        query = query.eq('title', conditions.title);
    }
    if (conditions.text) {
        query = query.or(`name.ilike.%${conditions.text}%,phone.ilike.%${conditions.text}%`);
    }

    //Limit item
    query = query
        .range(offset, offset + pageSize - 1)
        .order('id');

    //Execute query 
    const { data, error, count } = await query;
  
    if (error) {
      console.error("Error searching sailors:", error);
      return [];
    }

    return {
        items: data,
        total: count,  
      };
  };

  export const searchSailorByCondition = async (conditions, page, pageSize) => {
    const offset = (page - 1) * pageSize;

    const { data, error } = await supabase
        .rpc('search_sailors', { 
            keyword: conditions.text || null, 
            input_status: conditions.status || null, 
            input_title: conditions.title || null,
            input_limit: pageSize, 
            input_offset: offset
        }).single();
  
    if (error) {
      console.error("Error searching sailors:", error);
      return [];
    }

    return data;
  };
  

  export const addNewSailor = async (sailor) => {
    const { data, error } = await supabase
      .from('sailors')
      .insert([
        sailor
      ]).select();
  
    if (error) {
      console.error("Error adding sailor:", error);
      return null;
    }
    return data;
  };

  export const updateSailor = async (sailor) => {
    const { id, ...updateObj } = sailor;

    const { data, error } = await supabase
      .from('sailors')
      .update(updateObj)
      .eq('id', sailor.id);
  
    if (error) {
      console.error("Error updating sailor:", error);
      return null;
    }
    return data;
  };
  
  
  
  