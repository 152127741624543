import React, { useState, useEffect, useContext } from "react";
import Flatpickr from "react-flatpickr";
import { ACTION, STATE, STATES, TITLES } from "../../../utils/data";
import { AppContext } from "../../../context/AppContext";
import HeroIcon from "../../HeroIcon";
import {addNewSailor, updateSailor} from "../../../utils/sb-sailor.js"
import { validateSailor } from "../../../utils/helper.js";
import { formatDate } from "date-fns";
import "flatpickr/dist/themes/material_red.css";

const AddEditSailor = () => {

  const { appState, setAppState } = useContext(AppContext);
  const [editProfile, setEditProfile] = useState(appState.profile);
  const [wasValidated, setWasValidated] = useState(false);
  const [invalidCtrl, setInvalidCtrl] = useState({});
  const closeElement = React.useRef(null);

  const dateOptions = {
    altInput: true,
    dateFormat: "Y-m-d",  
    altFormat: "d/m/Y",  
    // mode: "single",
    allowInput: true,
  };
  const onInputChange = (event) => {
    // setAppState({
    //   ...appState,
    //   profile: { ...editProfile, [event.target.name]: event.target.value },
    // });
    const newValue = event.target.name === 'charge' ? event.target.checked : event.target.value;
    setEditProfile({ ...editProfile, [event.target.name]: newValue });
  };

  const onDateChange = (date, name) => {
    // setAppState({ ...appState, profile: { ...editProfile, [name]: date } });
    setEditProfile({ ...editProfile, [name]: date });
  };

  const handleSaveClick = async () => {
    if (!editProfile) {
      return;
    }

    setWasValidated(true);
    const checkValidCtrl = validateSailor(editProfile);
    setInvalidCtrl(checkValidCtrl);

    if (checkValidCtrl) {
      console.log('Invalid input....', checkValidCtrl);
      return;
    }
    
    if (!editProfile.id) {
      await addNewSailor(editProfile);
    } else {
      await updateSailor(editProfile);
    }
    //Trigger search for sailor list page
    setAppState({ ...appState, search: true });
    closeElement.current?.click();
  };

  useEffect(() => {}, []);

  return (
    <>
      <div className="offcanvas-header border-bottom py-2">
        <span className="offcanvas-title fs-5 fw-bold">
          <HeroIcon name="UserIcon" width={20} className="me-1" />{" "}
          {appState.action === ACTION.ADD
            ? "Thêm Thuyền Viên"
            : "Sửa Thông Tin"}
        </span>
        <button
          type="button"
          className="btn-close pl-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body">
        <form>
          <div className="form-floating mb-2">
            <input
              name="name"
              placeholder=""
              required
              type="text"
              className={`form-control form-control-sm ${wasValidated && invalidCtrl?.name ? 'invalid' : ''}`}
              value={editProfile.name || ""}
              autoComplete="off"
              onChange={onInputChange}
            />
            <label>Họ và tên</label>
          </div>
          <div className="row gx-2 mb-2">
            <div className="col-6 align-items-center">
              <div className="form-floating">
                <select
                  className={`form-select form-select-sm ${wasValidated && invalidCtrl?.title ? 'invalid' : ''}`}
                  id="floatingSelect"
                  name="title"
                  value={editProfile.title || ""}
                  onChange={onInputChange}
                  aria-label="Floating label select example"
                  placeholder=""
                  required
                >
                  <option>Chọn chức danh</option>
                  {TITLES.map((title, index) => (
                    <option value={title.shortName} key={index}>
                      {title.viName}
                    </option>
                  ))}
                </select>
                <label htmlFor="floatingSelect">Chức Danh</label>
              </div>
            </div>
            <div className="col-6 align-items-center">
              <div className="form-floating">
                <input
                  type="text"
                  name="phone"
                  onChange={onInputChange}
                  className={`form-control form-control-sm ${wasValidated && invalidCtrl?.phone ? 'invalid' : ''}`}
                  value={editProfile.phone || ""}
                  autoComplete="off"
                  placeholder=""
                />
                <label>Điện Thoại</label>
              </div>
            </div>
          </div>
          <div className="row gx-2 mb-2">
            <div className="col-6 align-items-center">
              <div className="form-floating">
                <Flatpickr
                  id="floatingDate"
                  value={editProfile.birthday}
                  options={{...dateOptions, defaultDate: '2000-01-01'}}
                  onChange={([date]) => onDateChange(date, "birthday")}
                  className="form-control form-control-sm"
                  placeholder=""
                  autoComplete="off"
                />
                <label htmlFor="floatingDate">Ngày Sinh</label>
              </div>
            </div>
            <div className="col-6 align-items-center">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  name="home_town"
                  onChange={onInputChange}
                  value={editProfile.home_town || ""}
                  autoComplete="off"
                  placeholder=""
                />
                <label htmlFor="floatingInput">Quê Quán</label>
              </div>
            </div>
          </div>

          <div className="row gx-2 mb-2">
            <div className="col-6 align-items-center">
              <div className="form-floating">
                <input
                  type="text"
                  name="exp"
                  className="form-control form-control-sm"
                  value={editProfile.exp || "0"}
                  onChange={onInputChange}
                  placeholder=""
                />
                <label htmlFor="floatingInput">Kinh Nghiệm</label>
              </div>
            </div>
            <div className="col-6 align-items-center">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  name="fee"
                  onChange={onInputChange}
                  value={editProfile.fee || ""}
                  autoComplete="off"
                  placeholder=""
                />
                <label htmlFor="floatingInput">Phí Dịch Vụ</label>
              </div>
            </div>
          </div>
          <hr className="border border-dark border-2" />
          <div className="row gx-2 mb-2">
            <div className="col-md-6 align-items-center">
              <div className="form-floating">
                <select
                  className={`form-select form-select-sm ${wasValidated && invalidCtrl?.status ? 'invalid' : ''}`}
                  id="floatingSelect"
                  name="status"
                  value={editProfile.status || ""}
                  onChange={onInputChange}
                  aria-label="Floating label select example"
                >
                  <option>Chọn Tình Trạng</option>
                  {STATES.map((state, index) => (
                    <option value={state.id} key={index}>
                      {state.text.toUpperCase()}
                    </option>
                  ))}
                </select>
                <label htmlFor="floatingSelect">Tình Trạng</label>
              </div>
            </div>
            <div className="col-md-6 text-center">
              {(editProfile.status === STATE.DOC_COMPLETED ||
                editProfile.status === STATE.ON_BOARD ||
                editProfile.status === STATE.OFF_BOARD) && (
                <>
                  <div className="form-check form-switch d-flex justify-content-center">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckDefault"
                      name="charge"
                      checked={editProfile.charge}
                      onChange={onInputChange}
                    />
                  </div>
                  <label
                    className="form-check-label"
                    htmlFor="flexSwitchCheckDefault"
                  >
                    Đã thanh toán phí
                  </label>
                </>
              )}
            </div>
          </div>
          {(editProfile.status === STATE.DOC_COMPLETED ||
            editProfile.status === STATE.ON_BOARD ||
            editProfile.status === STATE.OFF_BOARD) && (
            <>
              <div className="row gx-2 mb-2">
                <div className="col-6 align-items-center">
                  <div className="form-floating">
                    <Flatpickr
                      id="floatingDate"
                      value={editProfile.onboard_date || null}
                      options={dateOptions}
                      onChange={([date]) => onDateChange(date, "onboard_date")}
                      className="form-control form-control-sm"
                      autoComplete="off"
                      placeholder=""
                    />
                    <label htmlFor="floatingDate">Ngày Lên Tàu</label>
                  </div>
                </div>
                <div className="col-6 align-items-center">
                  <div className="form-floating">
                    <Flatpickr
                      id="floatingDate"
                      options={dateOptions}
                      value={editProfile.offboard_date || ""}
                      onChange={([date]) => onDateChange(date, "offboard_date")}
                      className="form-control form-control-sm"
                      autoComplete="off"
                      placeholder=""
                    />
                    <label htmlFor="floatingDate">Ngày Xuống Tàu</label>
                  </div>
                </div>
              </div>

              <div className="row gx-2 mb-2">
                <div className="col-6 align-items-center">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      value={editProfile.vessel_owner || ""}
                      name="vessel_owner"
                      onChange={onInputChange}
                      autoComplete="off"
                      placeholder=""
                    />
                    <label htmlFor="floatingInput">Chủ Tàu</label>
                  </div>
                </div>
                <div className="col-6 align-items-center">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      value={editProfile.vessel_name || ""}
                      name="vessel_name"
                      onChange={onInputChange}
                      autoComplete="off"
                      placeholder=""
                    />
                    <label htmlFor="floatingInput">Mã Tàu</label>
                  </div>
                </div>
              </div>
            </>
          )}
          {(editProfile.status === STATE.FEE_DISAGREED ||
            editProfile.status === STATE.USER_REFUSE ||
            editProfile.status === STATE.OWNER_REFUSE) && (
            <div className="form-floating mb-2">
              <textarea
                className={`form-control form-control-sm ${wasValidated && invalidCtrl?.reason ? 'invalid' : ''}`}
                placeholder="Leave a comment here"
                id="floatingTextarea"
                name="reason"
                value={editProfile.reason || ""}
                onChange={onInputChange}
              ></textarea>
              <label htmlFor="floatingTextarea">Lý Do</label>
            </div>
          )}
          <div className="form-floating mb-2">
            <textarea
              className="form-control form-control-sm"
              placeholder="Leave a comment here"
              id="floatingTextarea"
              name="note"
              value={editProfile.note || ""}
              onChange={onInputChange}
            ></textarea>
            <label htmlFor="floatingTextarea">Ghi Chú</label>
          </div>
        </form>
      </div>
      <div className="bottom-0 start-0 end-0 p-3 d-flex justify-content-end">
        
            <button
              ref={closeElement}
              className="btn btn-outline-primary"
              data-bs-dismiss="offcanvas"
            >
              Huỷ Bỏ
            </button>
            <button onClick={handleSaveClick} className="btn btn-primary ms-2">
              Lưu Thay Đổi
            </button>
         
      </div>
    </>
  );
};

export default AddEditSailor;
