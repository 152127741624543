import React, { useContext } from "react";
import { ReactComponent as SailorIcon } from "../../../assets/sailor.svg";
import { ReactComponent as VesselIcon } from "../../../assets/vessel.svg";
import HeroIcon from "../../HeroIcon";
import { setBgByStatus, getStatusText, getTitle } from "../../../utils/helper";
import { ACTION, STATE } from "../../../utils/data";
import { AppContext } from "../../../context/AppContext";
import { formatDate, parseISO } from "date-fns";

const SailorProfile = ({ profile }) => {
  const { appState, setAppState } = useContext(AppContext);

  const handleEditClick = () => {
    console.log(appState.profile);
    
    setAppState({ ...appState, action: ACTION.EDIT });
  };

  return (
    <>
      <div className="offcanvas-header border-bottom py-2">
        <span className="offcanvas-title fs-5 fw-bold">
          <HeroIcon name="UserIcon" width={20} className="me-1" />{" "}
          {profile?.name || ""}
        </span>
        <button
          type="button"
          className="btn-close pl-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body">
        <div className="profile">
          <div className="card bg-transparent h-100 text-center border-0">
            <div className="card-img-top d-flex justify-content-center align-items-center shadow-md">
              <SailorIcon width={64} className="" />
            </div>
            <div className="card-body py-1">
              <span className="fw-bold">{profile.title}</span>
              <p className="card-text">{getTitle(profile.title)}</p>
            </div>
            {(profile.status === STATE.DOC_COMPLETED ||
              profile.status === STATE.ON_BOARD ||
              profile.status === STATE.OFF_BOARD) && (
              <span
                className={`fs-6 position-absolute top-0 end-0 badge rounded-pill ${
                  profile.charge ? "bg-success" : "bg-danger"
                }`}
              >
                {profile.charge ? "Đã thu phí" : "Chưa thu phí"}
              </span>
            )}
          </div>
          <div className="row border rounded mt-3 py-3 px-4 gx-0">
            <div className="col-6 align-items-center">
              <div className="d-flex align-items-start">
                <HeroIcon
                  name="HeartIcon"
                  width={20}
                  className="me-1 text-primary"
                />
                <span
                  className={`badge text-dark text-uppercase fw-bold ${setBgByStatus(
                    profile.status
                  )}`}
                >
                  {getStatusText(profile.status)}
                </span>
              </div>
              <div className="d-flex align-items-start">
                <HeroIcon
                  name="CalendarDaysIcon"
                  width={20}
                  className="me-1 text-primary"
                />
                <span>{formatDate(profile.onboard_date, "dd/MM/yyyy")}</span>
              </div>
              <div className="d-flex align-items-start">
                <VesselIcon width={20} className="me-1 text-primary" />
                <span>{profile.vessel_owner}</span>
              </div>
            </div>
            <div className="col-6 align-items-center border-start ps-4">
              <div className="d-flex align-items-start">
                <HeroIcon
                  name="PhoneIcon"
                  width={18}
                  className="me-1 text-primary"
                />
                <span>{profile.phone}</span>
              </div>
              <div>
                <HeroIcon
                  name="CalendarDaysIcon"
                  width={20}
                  className="me-1 text-primary"
                />
                <span>{formatDate(profile.birthday, "dd/MM/yyyy")}</span>
              </div>
              <div>
                <HeroIcon
                  name="HomeModernIcon"
                  width={20}
                  className="me-1 text-primary"
                />
                <span>{profile.home_town}</span>
              </div>
            </div>
          </div>
          <div className="col border rounded mt-3 py-3 px-4 gx-0">
            {(profile.status === STATE.DOC_COMPLETED ||
              profile.status === STATE.ON_BOARD ||
              profile.status === STATE.OFF_BOARD) && (
              <div>
                <span className="fw-bold fst-italic">Thông tin tàu</span>
                <p>
                  {profile.vessel_name} - {profile.vessel_owner}
                </p>

                <span className="fw-bold fst-italic">Thời gian</span>
                <p>{`Từ ${formatDate(profile.onboard_date, "dd/MM/yyyy")} đến ${
                  profile.offboard_date ? formatDate(profile.offboard_date, "dd/MM/yyyy") : "..."
                }`}</p>
              </div>
            )}

            {(profile.status === STATE.FEE_DISAGREED ||
              profile.status === STATE.USER_REFUSE ||
              profile.status === STATE.OWNER_REFUSE) && (
              <div>
                <span className="fw-bold fst-italic">Lý do</span>
                <p>{profile.reason}</p>
              </div>
            )}

            <div>
              <span className="fw-bold fst-italic">Ghi chú</span>
              <p>{profile.note}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-0 start-0 end-0 p-3 d-flex justify-content-end">
        <button className="btn btn-outline-primary" onClick={handleEditClick}>
          Chỉnh Sửa
        </button>
      </div>
    </>
  );
};

export default SailorProfile;
