import { TITLES, STATE, STATES } from "./data";
import { parseISO } from "date-fns";

export const getTitle = (shortName) => {
  const title = TITLES.find((t) => t.shortName === shortName);
  return title?.viName || "";
};

export const getStatusText = (status) => {
    const state = STATES.find((s) => s.id === status);
  return state?.text || "";
};

export const setBgByStatus = (status) => {
  switch (status) {
    case STATE.ON_BOARD:
      return "bg-info-subtle";
    case STATE.OFF_BOARD:
      return "bg-success-subtle";
    case STATE.FEE_DISAGREED:
      return "bg-danger-subtle";
    case STATE.USER_REFUSE:
    case STATE.OWNER_REFUSE:
      return "bg-warning-subtle";
    default:
      return "bg-secondary-subtle";
  }
};

export const validateSailor = (sailor) => {
  let invalidCtrl = {};
  const requiredStr = ['name', 'phone', 'title', 'status'];
  const refuseState = [STATE.FEE_DISAGREED, STATE.USER_REFUSE, STATE.OWNER_REFUSE];

  Object.keys(sailor).forEach((key) => {
    if(requiredStr.includes(key) && sailor[key] === '') {
      invalidCtrl[key] = true;
    }
  });

  requiredStr.forEach((prop) => {
    if (!sailor[prop]) {
      invalidCtrl[prop] = true;
    }
  });

  if (refuseState.includes(sailor.status) && (!sailor.reason || sailor.reason === '')) {
    invalidCtrl.reason = true;
  }

  return Object.keys(invalidCtrl).length > 0 ? invalidCtrl : null;
}

export const strToDate = (str) => {
  return str ? parseISO(str) : null;
}
