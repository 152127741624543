import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import "flatpickr/dist/themes/material_green.css";

import Layout from "./components/Layout";
import Landing from "./pages/Landing";
import Error404 from "./pages/404";
import SailorTitle from "./components/authed/SailorTitle";
import AuthedLayout from "./components/authed/Layout";
import SailorList from "./components/authed/SailorList";
import { AppProvider } from "./context/AppContext";
import Login from "./pages/Login";

function App() {
  return (
    <AppProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Landing />} />
          </Route>

          <Route path="/authed" element={<AuthedLayout />}>
            <Route index element={<SailorList />} />
            <Route path="/authed/sailor-title" element={<SailorTitle />} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </Router>
    </AppProvider>
  );
}

export default App;
