import React from "react";
import { TITLES } from "../../utils/data";

const SailorTitle = () => {

  return (
    <div className="pt-2 pt-md-3">
      <div className="card">
        <div className="card-body overflow-auto p-0 pt-1">
          <table className="table table-sm table-striped table-responsive-md">
            <thead>
              <tr>
                <th className="pb-2 sticky-col first-col">#</th>
                <th className="pb-2 text-nowrap sticky-col second-col" scope="col">
                  Chức Danh
                </th>
                <th className="pb-2 text-nowrap">Lương (Tr)</th> 
                <th className="pb-2" scope="col">Tiếng Anh</th>
                <th className="pb-2" scope="col">Tiếng Việt</th>
              </tr>
            </thead>
            <tbody>
              {TITLES.map((item, index) => (
                <tr key={index}>
                  <td className="sticky-col first-col">{index + 1}</td>
                  <td className="text-bold sticky-col second-col">
                    {item.shortName}
                  </td>
                  <td className="text-nowrap">{item.salary}</td>
                  <td className="text-nowrap">{item.engName}</td>
                  <td className="text-nowrap">{item.viName}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SailorTitle;
