import React, { useState, useContext } from "react";
import { motion } from "framer-motion";
import Logo from "../../logo.svg";
import HeroIcon from "../HeroIcon";
import { AppContext } from "../../context/AppContext";
import { ACTION, STATE } from "../../utils/data";

const AuthedHeader = () => {
    const { appState, setAppState } = useContext(AppContext);
    const [isOpen, setIsOpen] = useState(false);

  const menuItems = [
    {
      link: "/authed",
      text: "Thuyền Viên",
    },
    {
      link: "/authed/partners",
      text: "Đối tác",
    },
    {
      link: "/authed/sailor-title",
      text: "Chức danh",
    },
  ];

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const menuItemClick = () => {
    if (isOpen) {
      setIsOpen(!isOpen);
    }
  };

  const addSailor = () => {
    setAppState({...appState, action: ACTION.ADD, profile: {status: STATE.CONNECT}});
  };

  return (
    <motion.nav
      className={`navbar navbar-expand-lg fixed-top bg-secondary-subtle shadow-sm border-bottom p-0 scrolled`}
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className={`container p-0 ${isOpen ? "mobile-nav" : ""}`}>
        <HeroIcon
          name={`${isOpen ? "XMarkIcon" : "Bars3Icon"}`}
          className="mx-2 d-md-none text-dark"
          width={36}
          onClick={toggleMenu}
        />
        <a className="navbar-brand ms-2 ms-md-0 py-0" href="/">
          <img src={Logo} width={56} />
        </a>

        <div
          className={`collapse navbar-collapse text-uppercase`}
          id="navbarNav"
        >
          <ul className="navbar-nav">
            {menuItems.map((item, index) => (
              <li className="nav-item px-md-2" key={index}>
                <HeroIcon
                  name={"PaperAirplaneIcon"}
                  className="ms-3 me-2 d-md-none"
                  width={20}
                />
                <a
                  className="nav-link"
                  href={`${item.link}`}
                  onClick={menuItemClick}
                >
                  {item.text}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="language-selector me-2 me-md-0">
          <button className={`btn btn-outline-dark`} onClick={addSailor} data-bs-toggle="offcanvas" data-bs-target="#offcanvasProfile">
            <HeroIcon name="UserPlusIcon" width={24} />
            <span className="ms-2 d-none d-md-inline">
              {"Thêm Thuyền Viên"}
            </span>
          </button>
        </div>
      </div>
    </motion.nav>
  );
};

export default AuthedHeader;
